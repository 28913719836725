import { Button, Flex, Image } from '@chakra-ui/react';
import type { LinksFunction } from '@remix';
import { Form } from '@remix';
import LogoCard, { links as logoLinks } from '~/components/card/LogoCard';
import MicrosoftIcon from '~/assets/microsoft.svg';
import { SocialsProvider } from '~/strategies/index';

type MicrosoftIconSVGProps = {
    [x: string]: string | number;
};

export const links: LinksFunction = () => {
    return [...logoLinks()];
};

const MicrosoftIconSVG: React.FC<MicrosoftIconSVGProps> = (props) => {
    return (
        <>
            <Image {...props} src={MicrosoftIcon} />
        </>
    );
};

interface SocialButtonProps {
    provider: SocialsProvider;
    label: string;
}

const SocialButton: React.FC<SocialButtonProps> = ({ provider, label }) => (
    <Form action={`/auth/${provider}`} method="post">
        <Flex justifyContent={'center'}>
            <Button variant={'primary'} type={'submit'} display="flex">
                <MicrosoftIconSVG padding={2} strokeWidth="10px" stroke="black"></MicrosoftIconSVG>
                {label}
            </Button>
        </Flex>
    </Form>
);

const Login = () => {
    return (
        <Flex minHeight={'100vh'}>
            <LogoCard>
                <SocialButton provider={SocialsProvider.MICROSOFT} label="Login with Microsoft" />
            </LogoCard>
        </Flex>
    );
};

export default Login;
